import React, { useEffect } from "react"
import { useIdentityContext } from "../services/react-netlify-identity-gotrue"
import { useSubInfoContext } from "../services/subinfocontext"
import Layout from "../components/common/layout/layout"
import LoginForm from "../components/user/loginform"
import { Link, navigate } from "gatsby"
//import "../styles/mystyles.scss"

const SIBLoginPage = (props, { location }) => {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()

  // renavigate students once logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (subInfoContext.subInfo.isStudentCode) {
        navigate("/studenthome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  // and users once logged in
  useEffect(() => {
    if (subInfoContext.subInfo) {
      if (identity.user) {
        navigate("/app/userhome")
      }
    }
  }, [subInfoContext.subInfo, identity.user])

  const showBanner =
    props.location && props.location.state && props.location.state.showError

  return (
    <Layout>
      <div className={`${showBanner ? "" : "is-hidden"}`}>
        <RequiresLoginBanner/>
      </div>
      <SIBLoginHeader />
      <div className="container user-login-form-container">
        <div className="columns">
          <div className="column is-8-desktop is-offset-2-desktop">
            <LoginForm />
          </div>
        </div>
      </div>
    </Layout>
  )
}

const RequiresLoginBanner = () => {
  return (
    <div
      className={`has-text-left is-fluid has-background-primary
      `}
    >
      <div className="notification has-background-sibredorange">
        <div className="has-text-white has-text-centered">
          This action requires you to be logged in. Please log in or create an
          account, then try again.
        </div>
      </div>
    </div>
  )
}

const SIBLoginHeader = (props) => {
  return (
    <>
      <section className="page-header bg-paper bg-tear-white">
        <div className="container">
          <div className="columns">
            <div className="column is-8-desktop is-offset-2-desktop">
              <h1>Log in</h1>
              <p>
                Don't have an account?{" "}
                <Link className="has-text-sibredorange" to="/signup">
                  Sign up
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SIBLoginPage
