import React, { useState } from "react"
import { useIdentityContext } from "../../services/react-netlify-identity-gotrue"
import { Link } from "gatsby"

import { useForm } from "react-hook-form"
import { useSubInfoContext } from "../../services/subinfocontext"

import Loader from "react-loader-spinner"

export default function UserLoginForm({ navigateTarget }) {
  const identity = useIdentityContext()
  const subInfoContext = useSubInfoContext()

  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({})

  const [formError, setFormError] = useState(false)
  const [loggingIn, setLoggingIn] = useState(false)

  const onFormSubmit = async (data) => {
    setLoggingIn(true)
    setFormError(false)
    if (data.email.length > 0 && data.password.length > 0) {
      loginUser(data)
    } else if (data.student_code.length > 0) {
      loginStudent(data)
    }
  }

  const loginUser = async (data) => {
    await identity
      .login({ email: data.email, password: data.password })
      .then(() => {
        //navigateTarget && navigate(navigateTarget)
      })
      .catch((e) => {
        setLoggingIn(false)
        setFormError(e.message)
      })
  }

  const loginStudent = async (data) => {
    await subInfoContext
      .studentCodeLogin(data.student_code)
      .then((subInfo) => {
        setLoggingIn(false)
        if (
          Object.keys(subInfo).length === 0 &&
          subInfo.constructor === Object
        ) {
          setFormError("Invalid Login Code")
        }
      })
      .catch((e) => {
        setLoggingIn(false)
        setFormError(e.message)
      })
  }

  const emailOnChange = (e) => {
    let email = e.target.value
    setValue("email", e.target.value, { shouldValidate: false })
    if (email.length > 0) {
      setValue("student_code", "", { shouldValidate: false })
      clearErrors("student_code")
    }
  }

  const emailValidate = () => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    // Normal validation
    let studentCode = getValues("student_code")
    if (studentCode.length > 0) {
      return true
    }
    let email = getValues("email")
    if (email.length === 0) {
      return "Email is required"
    } else {
      if (pattern.test(email)) {
        return true
      } else {
        // set error message somehow
        return "Provided email address is invalid"
      }
    }
  }

  const passwordOnChange = (e) => {
    let password = e.target.value
    setValue("password", e.target.value, { shouldValidate: false })
    if (password.length > 0) {
      setValue("student_code", "", { shouldValidate: false })
      clearErrors("student_code")
    }
  }

  const passwordValidate = () => {
    // Normal validation
    let password = getValues("password")
    let studentCode = getValues("student_code")
    if (studentCode.length > 0) {
      return true
    }
    if (password.length === 0) {
      return "Password is required"
    } else {
      return true
    }
  }

  const studentCodeOnChange = (e) => {
    let studentCode = e.target.value
    setValue("student_code", e.target.value, { shouldValidate: false })
    if (studentCode.length > 0) {
      setValue("password", "", { shouldValidate: false })
      setValue("email", "", { shouldValidate: false })
      clearErrors("password")
      clearErrors("email")
    }
  }

  const studentCodeValidate = () => {
    return true
  }

  return (
    <>
      {formError && (
        <div className="notification-error-text py-1">{formError}</div>
      )}
      <div className={`${loggingIn ? "is-hidden" : ""}`}>
        <form id="user-submit-form" onSubmit={handleSubmit(onFormSubmit)}>
          <div className="pb-1">Email</div>
          <div className="field pb-1">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("email", {
                  validate: emailValidate,
                })}
                onChange={(e) => emailOnChange(e)}
                type="text"
                id="login-user-email"
                placeholder="Email"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-envelope"></i>
              </span>
              <span className="notification-error-text">
                {errors.email && errors.email.message}
              </span>
            </p>
          </div>
          <div className="pb-1">Password</div>
          <div className="field">
            <p className="control has-icons-left">
              <input
                className="input"
                {...register("password", {
                  validate: passwordValidate,
                })}
                onChange={(e) => passwordOnChange(e)}
                type="password"
                placeholder="Password"
                id="login-user-password"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.password && errors.password.message}
              </span>
            </p>
          </div>
          <div className="forgot-password-link">
            <Link to="/resetpwreq">Forgot your password?</Link>
          </div>
          <div className="has-text-sibredorange has-text-weight-bold py-1">
            OR
          </div>
          <div className="pb-1">Enter School Log-in Code</div>
          <div className="field">
            <p className="control has-icons-left pb-1">
              <input
                className="input"
                {...register("student_code", {
                  validate: studentCodeValidate,
                })}
                onChange={(e) => studentCodeOnChange(e)}
                type="text"
                placeholder="ABCD-EFGH-IGJL-MNOP"
                id="login-student-code"
              />
              <span className="icon is-small is-left">
                <i className="fas fa-lock"></i>
              </span>
              <span className="notification-error-text">
                {errors.student_code && errors.student_code.message}
              </span>
            </p>
          </div>
          <button
            form="user-submit-form"
            className="button is-black is-fullwidth"
            type="submit"
            id="login-user-submit-button"
          >
            Log in
          </button>
        </form>
      </div>
      <div className={`${loggingIn ? "" : "is-hidden"}`}>
        <div className="has-text-centered my-2">
          <Loader type="TailSpin" color="#000000" height={100} width={100} />
        </div>
        <div className="title is-5 has-text-centered mt-3">
          Logging In - A Moment, Please
        </div>
      </div>
    </>
  )
}
